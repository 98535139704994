// Composables
import { createRouter, createWebHistory } from "vue-router";

const routes = [{
        name: "AppLayout",
        path: "/app-layout",
        redirect: "/booked-appointments",
        component: () =>
            import ("@/layouts/AppLayout.vue"),
        meta: {
            requiresAuth: true,
        },
        children: [{
                name: "BookedAppointment",
                path: "/booked-appointments",
                component: () =>
                    import ("@/views/BookedAppointment.vue"),
                meta: {
                    isShowTitleInAppBar: true,
                    title: "Booked Appointments",
                },
            },
            {
                name: "VinScan",
                path: "/vin-scan",
                component: () =>
                    import ("@/views/VinScan.vue"),
                meta: {
                    isShowTitleInAppBar: false,
                    title: "VIN Scan",
                },
            },
            {
                name: "CustomerVehicleDetail",
                path: "/customer-vehicle-info",
                component: () =>
                    import ("@/views/CustAndVehicleInfo.vue"),
                meta: {
                    isShowTitleInAppBar: true,
                    title: "Customer Vehicle Info",
                },
            },
            {
                name: "VideoWalkaround",
                path: "/video-walkaround",
                component: () =>
                    import ("@/views/VideoWalkaround.vue"),
                meta: {
                    isShowTitleInAppBar: false,
                    title: "Video Walkaround",
                },
            },
            {
                name: "UploadWalkaroundFiles",
                path: "/upload-walkaround-files",
                component: () =>
                    import ("@/views/UploadWalkaroundFiles.vue"),
                meta: {
                    isShowTitleInAppBar: true,
                    title: "Uploaded Files",
                },
            },
            {
                name: "OilChangePackages",
                path: "/oil-packages",
                component: () =>
                    import ("@/views/OilChangePackages.vue"),
                meta: {
                    isShowTitleInAppBar: false,
                    title: "Oil Change Packages",
                },
            },
            {
                name: "services",
                path: "/services",
                component: () =>
                    import ("@/views/Services.vue"),
                meta: {
                    isShowTitleInAppBar: true,
                    title: "Services",
                },
            },
        ],
    },
    {
        name: "Login",
        path: "/",
        component: () =>
            import ("@/views/Login.vue"),
        meta: { requiresAuth: false, title: "Login" },
        beforeEnter: (to, from, next) => {
            /*if (localStorage.getItem("token")) {
                localStorage.clear();
            }*/
            if (JSON.parse(localStorage.getItem("token"))) {
                next({
                    path: "/app-layout",
                });
            } else {
                next();
            }
        },
    },
    {
        name: "PageNotFound",
        path: "/:pathMatch(.*)*",
        component: () =>
            import ("@/views/PageNotFound.vue"),
        meta: { requiresAuth: false, title: "PageNotFound" },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!JSON.parse(localStorage.getItem("token"))) {
            next({
                path: "/",
            });
        } else {
            next();
        }
    } else {
        next(); // make sure to always call next()!
    }
});

export default router;