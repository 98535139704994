<template>
  <div>
    <v-snackbar
      v-model="localSnackbar"
      :timeout="closeTimeout"
      :color="color"
      rounded="lg"
      class="ma-0"
      style="z-index: 9999 !important"
      text
      id="custom-snackbar"
    >
      <div class="d-flex">
        <v-icon color="white" size="20">
          {{ color == "success" ? "mdi-check-circle" : "mdi-close-circle" }}
        </v-icon>
        <span v-if="color == 'success'" class="pl-2" id="snack-bar-success-id">
          <b class="mr-1">Success! </b>
          {{ message }}
        </span>
        <span v-else class="pl-2 d-flex justify-center" id="snack-bar-error-id">
          <b class="mr-1">Error! </b>
          {{ message }}
        </span>
      </div>
      <template v-slot:actions>
        <v-btn
          density="compact"
          icon="mdi-close"
          color="white"
          size="small"
          class="mr-1 mt-1"
          @click="barClose"
        ></v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "SnackbarComponent",
  props: {
    snackbar: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
    },
    color: {
      type: String,
      default: "success",
    },
  },
  data() {
    return {
      localSnackbar: true,
      closeTimeout: 5 * 1000,
    };
  },
  mounted() {
    setTimeout(() => {
      this.barClose();
    }, this.closeTimeout);
  },
  methods: {
    /**
     * This method is responsible to close the snackbar.
     *
     * @return {void}
     */
    barClose() {
      this.$store.commit("set_snack_data", {});
    },
  },
};
</script>
<style scoped>
.success-text {
  color: #4caf50 !important;
}
.error-text {
  color: #ff5252 !important;
}
</style>
