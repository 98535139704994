import App from "./App.vue";
import { createApp } from "vue";

import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueTablerIcons from "vue-tabler-icons";
import { commonMixin } from "@/components/utilities/commonMixin.js";

import "@/assets/style/style.css";

const app = createApp(App);

app.use(router);
app.use(store);
app.use(vuetify);
app.use(VueTablerIcons);
app.mixin(commonMixin);
app.mount("#app");
