import CustomTooltip from "@/components/utilities/components/CustomTooltip.vue";
const commonMixin = {
  components: {
    CustomTooltip,
  },
  methods: {
    generateRandomString(maxLength = 10) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < maxLength; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    },
    /**
     * Formats a date string to like '11 Aug 2024'.
     *
     * @param {string} dateString - The date string in ISO format.
     * @returns {string} The formatted date string.
     */
    getFormattedDate(dateString) {
      let date = new Date(dateString);

      let options = { day: "2-digit", month: "short", year: "numeric" };
      return new Intl.DateTimeFormat("en-GB", options).format(date);
    },
    /**
     * Formats a date string to 'hh:mm a'.
     *
     * @param {string} dateString - The date string in ISO format.
     * @returns {string} The formatted time string.
     */
    getFormattedTime(dateString) {
      let date = new Date(dateString);

      let options = { hour: "2-digit", minute: "2-digit", hour12: true };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    },
  },
};
export { commonMixin };
