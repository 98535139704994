import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import apiRequest from "./../services/apiRequest";
import snackbar from "./modules/snackbar";
import auth from "./modules/auth";
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => {
    return {
      persistedState: { ...state.persistedState, ...state.auth },
      auth: state.auth,
      vinScanData: state.vinScanData,
      packageServiceDetail: state.packageServiceDetail,
      cartItems: state.cartItems,
      servicesItems: state.servicesItems,
      advisorsData: state.advisorsData,
    };
  },
});

const store = {
  state: {
    persistedState: {},
    vinScanData: {},
    previewVideoUrl: "",
    walkAroundVideoFile: null,
    packageServiceDetail: {},
    cartItems: [],
    servicesItems: [],
    advisorsData: [],
  },
  getters: {
    getPreviewVideoUrl: (state) => state.previewVideoUrl,
    getWalkAroundVideoFile: (state) => state.walkAroundVideoFile,
    getVinScanData: (state) => state.vinScanData,
    getPackageServiceDetail: (state) => state.packageServiceDetail,
    getCartItems: (state) => state.cartItems,
    getServicesItems: (state) => state.servicesItems,
    getAdvisorsData: (state) => state.advisorsData,
  },
  mutations: {
    logout(state) {
      //clear local storage
      localStorage.clear();
      delete state.persistedState;
      delete state.auth;
    },

    SET_PREVIEW_VIDEO_URL(state, data) {
      state.previewVideoUrl = data;
    },
    SET_WALKAROUND_VIDEO_FILE(state, data) {
      state.walkAroundVideoFile = data;
    },
    SET_VIN_SCAN_DATA(state, data) {
      state.vinScanData = data;
    },
    SET_PACKAGES_SERVICE_DETAIL(state, data) {
      state.packageServiceDetail = data;
    },
    SET_CART_ITEMS(state, data) {
      state.cartItems = data;
    },
    SET_SERVICES_ITEMS(state, data) {
      state.servicesItems = data;
    },
    SET_ADVISORS_DATA(state, data) {
      state.advisorsData = data;
    },
  },
  actions: {
    /**
     * This function is used to show snackbar for both cases i.e. success or error
     *
     * @param response
     * @returns void
     */
    handleSnackBar(context, response) {
      let message,
        color = "error";
      if (response?.data?.message) {
        color = "success";
        message = response?.data?.message;
      } else if (response?.response?.data?.message) {
        message = response.response.data.message;
      } else {
        message = "Something went wrong";
      }
      context.commit("set_snack_data", {
        message,
        color,
        snackbar: true,
      });
    },
    /**
     * This method is responsible to read data
     *
     * @param {String} url
     * @return promise
     */
    readData(context, url) {
      return new Promise((resolve, reject) => {
        let done = async (response) => {
          if (response.status == 200 || response.status == 202) {
            resolve(response);
          } else {
            context.dispatch("handleSnackBar", response);
            reject(response);
          }
        };
        apiRequest.get(url, done);
      });
    },
    /**
     * This method is responsible to make an api call to read data for post request
     *
     * @param {Object} payload contain url and data
     * @return promise
     */
    readPostData(context, payload) {
      return new Promise((resolve, reject) => {
        let done = async (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            context.dispatch("handleSnackBar", response);
            reject(response);
          }
        };
        apiRequest.post(payload.url, payload.data, done);
      });
    },
    /**
     * This method is responsible to make an api call to add/update/delete data for post request
     *
     * @param {Object} payload contain url and data
     * @return promise
     */
    postData(context, payload) {
      return new Promise((resolve, reject) => {
        let done = async (response) => {
          if (response.status == 200 || response.status == 201) {
            resolve(response);
          } else {
            context.dispatch("handleSnackBar", response);
            reject(response);
          }
        };
        apiRequest.post(payload.url, payload.data, done);
      });
    },
    /**
     * This function is used to get latest data on page refresh
     *
     * @returns void
     */
    getUserData(context) {
      return new Promise((resolve, reject) => {
        let done = async (response) => {
          if (response.status == 200) {
            context.commit("SET_USER_DATA", response.data.data?.user);
            resolve(response);
          } else {
            reject(response);
          }
        };
        apiRequest.get("getUserData", done);
      });
    },

    getAllAdvisorsData(context) {
      return new Promise((resolve, reject) => {
        let done = async (response) => {
          if (response.status == 200) {
            context.commit("SET_ADVISORS_DATA", response.data.data?.users);
            resolve(response);
          } else {
            reject(response);
          }
        };
        apiRequest.get("readUsers?role=advisor", done);
      });
    },
  },

  plugins: [vuexLocal.plugin],
  modules: {
    snackbar,
    auth,
  },
};

export default createStore(store);
