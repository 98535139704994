<template>
  <div>
    <div
      v-if="!showInChip"
      ref="tooltipContent"
      :class="[
        `text-truncate tooltip-truncation ${addOnClasses}`,
        isEllipsisActive ? 'pointer' : '',
      ]"
      :style="`-webkit-line-clamp: ${truncationLine} !important;`"
      @mouseover="checkEllipsis()"
    >
      {{ fullText }}
    </div>
    <div v-else>
      <v-chip>
        <span
          ref="tooltipContent"
          class="text-truncate tooltip-truncation"
          :class="isEllipsisActive ? 'pointer' : ''"
          @mouseover="checkEllipsis()"
          :style="`-webkit-line-clamp: ${truncationLine} !important;`"
        >
          {{ fullText }}
        </span>
      </v-chip>
    </div>
    <v-tooltip
      activator="parent"
      location="bottom"
      :disabled="!isEllipsisActive"
      content-class="white tooltip-bottom"
    >
      <span class="tooltip-text">
        {{ fullText }}
      </span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  name: "CustomTooltip",
  props: [
    "fullText",
    "truncationLine",
    "showInChip",
    "chipColor",
    "addOnClasses",
  ],
  data() {
    return {
      isEllipsisActive: false,
    };
  },
  methods: {
    checkEllipsis() {
      if (
        (this.$refs.tooltipContent &&
          this.$refs.tooltipContent.offsetWidth <
            this.$refs.tooltipContent.scrollWidth) ||
        this.$refs.tooltipContent.offsetHeight <
          this.$refs.tooltipContent.scrollHeight
      ) {
        this.isEllipsisActive = true;
      } else {
        this.isEllipsisActive = false;
      }
    },
  },
};
</script>
<style scoped>
.tooltip-truncation {
  -webkit-box-orient: vertical !important;
  display: -webkit-box !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: normal !important;
  line-height: normal !important;
}
</style>
