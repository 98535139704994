const state = {
  snack: {},
};

const mutations = {
  set_snack_data(state, data) {
    state.snack = data;
  },
};

export default {
  state,
  mutations,
};
