<template>
  <snackbar-component
    v-if="Object.keys(this.$store.state.snackbar.snack)?.length"
    :snackbar="snackBar.snackbar"
    :message="snackBar.message"
    :color="snackBar.color"
  />
  <div class="background-img-one">
    <router-view />
  </div>
</template>

<script>
import SnackbarComponent from "@/components/utilities/components/SnackbarComponent.vue";
export default {
  name: "App",
  components: {
    SnackbarComponent,
  },
  watch: {
    /**
     * This watcher is responsible to watch for any route changes and then set title of the page accordingly
     *
     * @param {object} to The payload object contains meta
     * @return none
     */
    $route: {
      handler(to) {
        document.title = to?.meta?.title
          ? `${to.meta.title} - Dealers VIN Menu`
          : "Dealers VIN Menu";
      },
      immediate: true,
    },
  },
  computed: {
    snackBar() {
      return this.$store.state.snackbar.snack;
    },
  },
};
</script>
