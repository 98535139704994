const state = {
  user: {},
};

const getters = {
  getLoggedInUserData: (state) => state?.user,
};

const mutations = {
  SET_USER_DATA(state, data) {
    state.user = data;
  },
};

export default {
  state,
  getters,
  mutations,
};
